<script lang="ts" setup>
import { useAppStore } from '../../stores/app.ts';

const app = useAppStore();
</script>

<template>
  <VMain>
    <img
      :src="`/images/logo/${app.globalTheme === 'light' ? 'light_15_years.png' : 'dark_15_years.png'}`"
      alt="logo"
      height="96"
    />
    <VImg
      src="/images/hero-background-yellow.svg"
      alt="background"
      aspect-ratio="2.7/1"
      class="banner bg-primary"
      height="300"
      width="100%"
    />
    <VContainer :fluid="!app.isContentBoxed">
      <VLayout>
        <slot />
      </VLayout>
    </VContainer>
  </VMain>
</template>
