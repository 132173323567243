export const OrderMessages = {
  address: {
    your: {
      title: 'Jouw aansluitadres',
      text: 'Dit is het adres waarvoor je een aansluiting wilt aanvragen. Klopt dit adres niet? Neem dan contact op met onze klantenservice door te bellen naar 0800 6000.',
    },
  },
  article: {
    title: 'Artikel',
    billingFrequency: {
      one_time: 'Eenmalig',
      monthly: 'Maandelijks',
    },
    billingTerm: 'Contracttermijn: {term} maanden',
    multiple: 'Meerdere artikelen kunnen worden geselecteerd.',
    ppu: 'Prijs per eenheid',
    selected: 'Geselecteerde artikelen',
  },
  confirm: {
    text: 'Controleer jouw gegevens en bevestig jouw bestelling.',
    title: 'Bevestigen',
    contractTerm: {
      label: 'Contracttermijn',
      text: 'Ik ga akkoord met het contract en de looptijd van {term} maanden.',
    },
    termsAndConditions: {
      label: 'Algemene voorwaarden',
      text: 'Ik ga akkoord met de <a href="https://www.forefreedom.nl/algemene-voorwaarden/" target="_blank">algemene voorwaarden</a> en <a href="https://forefreedom.nl/wp-content/uploads/2022/05/Privacybeleid-Fore-Freedom-2022.pdf" target="_blank">privacybeleid</a>.',
    },
  },
  costs: {
    connection: 'Aansluitkosten',
    from: 'Vanaf',
    nonRecurring: 'Eenmalige kosten',
    recurring: 'Terugkerende kosten',
  },
  details: {
    administrativeDetailsExplanation: 'Deze gegevens worden gebruikt voor de facturatie.',
    administrativeSameAddress: 'Factuuradres is gelijk aan aansluitadres',
    administrativeAddress: 'Factuuradres',
    text: 'Vul jouw gegevens in.',
    title: 'Jouw gegevens',
  },
  offer: {
    text: 'We hebben het volgende aanbod voor je samengesteld.',
    title: 'Aanbod',
  },
  validation: {
    featureRequired: 'Maak een keuze om verder te gaan.',
  },
};
