import { QuoteMessages } from './quote.messages.ts';
import { OrderMessages } from './order.messages.ts';
import { CompanyMessages } from './company.messages.ts';
import { AddressMessages } from './address.messages.ts';
import { ContactMessages } from './contact.messages.ts';
import { DeviceQrMessages } from './device-qr.messages.ts';

const nl = {
  back: 'Terug',
  continue: 'Volgende',
  error: {
    sendBack: 'Stuur me terug',
    notFound: 'Sorry, we kunnen de pagina die je zoekt niet vinden.',
    unexpected: 'Oeps! Er ging iets mis. Onze experts zijn er mee bezig.',
    help: 'Voor hulp kan je ons bellen op',
  },
  home: {
    pageTitle: 'Home',
    welcome: 'Welkom op het ordersysteem van Fore Freedom!',
  },
  quantity: 'Aantal',
  submit: 'Verstuur',
  total: 'Totaal',

  address: AddressMessages,
  company: CompanyMessages,
  contact: ContactMessages,
  deviceQr: DeviceQrMessages,
  order: OrderMessages,
  quote: QuoteMessages,
};

export default nl;
